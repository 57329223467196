// App.tsx
import Modal from 'react-modal';

import './App.css';
import { AppRouter } from './router/AppRouter';

Modal.setAppElement('#root');

export const App = () => (
  <div className="App" data-testid="app-root">
    <AppRouter />
  </div>
);
