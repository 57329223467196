/* eslint-disable no-console */
// src/services/api.ts

import axios from 'axios';

// Base URL configuration
const BASE_URL =
  import.meta.env.MODE === 'development'
    ? 'http://localhost:4000/api'
    : 'https://api.roundcodebox.com/api';

// Create an Axios instance
export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

// Interceptor for request logging
api.interceptors.request.use((request) => {
  console.log(
    `[API Request] ${request.method?.toUpperCase()} ${request.baseURL}${request.url}`
  );

  return request;
});

// Interceptor for response logging
api.interceptors.response.use(
  (response) => {
    console.log('[API Response]', response.data);

    return response;
  },
  (error) => {
    if (error.response) {
      console.error('[API Error]', error.response.data);
    } else if (error.request) {
      console.error('[API Error] No response received:', error.request);
    } else {
      console.error('[API Error] Request setup issue:', error.message);
    }

    return Promise.reject(error);
  }
);
