// src/router/routes.tsx
import { Suspense } from 'react';

import { createBrowserRouter, Outlet } from 'react-router-dom';

import { BackToTopButtonWrapper } from '../components/common/BackToTopButtonWrapper'; // Ensure this import is correct
import { Footer } from '../components/common/Footer';
import { Header } from '../components/common/Header';
import { lazyWithNamedExports } from '../utils/lazyWithNamedExports';

// Dynamically imported components using lazyWithNamedExports
const HomePage = lazyWithNamedExports(
  () => import('../pages/HomePage'),
  'HomePage'
);
const CardGenerator = lazyWithNamedExports(
  () => import('../components/CardGenerator/CardGenerator'),
  'CardGenerator'
);
const HtmlMinifier = lazyWithNamedExports(
  () => import('../components/HtmlMinifier/HtmlMinifier'),
  'HtmlMinifier'
);
const CssMinifier = lazyWithNamedExports(
  () => import('../components/CssMinifier/CssMinifier'),
  'CssMinifier'
);
const JsMinifier = lazyWithNamedExports(
  () => import('../components/JsMinifier/JsMinifier'),
  'JsMinifier'
);
const CardGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/CardGeneratorDetails'),
  'CardGeneratorDetails'
);
const GridGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/GridGeneratorDetails'),
  'GridGeneratorDetails'
);
const FlexboxGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/FlexboxGeneratorDetails'),
  'FlexboxGeneratorDetails'
);
const TextShadowGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/TextShadowGeneratorDetails'),
  'TextShadowGeneratorDetails'
);
const GridGenerator = lazyWithNamedExports(
  () => import('../components/GridGenerator/GridGenerator'),
  'GridGenerator'
);
const FlexboxGenerator = lazyWithNamedExports(
  () => import('../components/FlexboxGenerator/FlexboxGenerator'),
  'FlexboxGenerator'
);
const TooltipGenerator = lazyWithNamedExports(
  () => import('../components/TooltipGenerator/TooltipGenerator'),
  'TooltipGenerator'
);
const TooltipGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/TooltipGeneratorDetails'),
  'TooltipGeneratorDetails'
);
const TextShadowGenerator = lazyWithNamedExports(
  () => import('../components/TextShadowGenerator/TextShadowGenerator'),
  'TextShadowGenerator'
);
const BlogPage = lazyWithNamedExports(
  () => import('../pages/BlogPage'),
  'BlogPage'
);
const FaqPage = lazyWithNamedExports(
  () => import('../pages/FaqPage'),
  'FaqPage'
);
const AboutPage = lazyWithNamedExports(
  () => import('../pages/AboutPage'),
  'AboutPage'
);
const ContactPage = lazyWithNamedExports(
  () => import('../pages/ContactPage'),
  'ContactPage'
);
const PrivacyPolicyPage = lazyWithNamedExports(
  () => import('../pages/PrivacyPolicyPage'),
  'PrivacyPolicyPage'
);
const SitemapPage = lazyWithNamedExports(
  () => import('../pages/SitemapPage'),
  'SitemapPage'
);
const TermsOfServicePage = lazyWithNamedExports(
  () => import('../pages/TermsOfServicePage'),
  'TermsOfServicePage'
);
const BoxShadowGenerator = lazyWithNamedExports(
  () => import('../components/BoxShadowGenerator/BoxShadowGenerator'),
  'BoxShadowGenerator'
);
const BoxShadowGeneratorDetails = lazyWithNamedExports(
  () => import('../pages/BoxShadowGeneratorDetails'),
  'BoxShadowGeneratorDetails'
);
const NotFound = lazyWithNamedExports(
  () => import('../components/NotFound'),
  'NotFound'
);

export const Layout = () => (
  <div>
    <Header />
    <div className="content">
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
    </div>
    <Footer />
    <BackToTopButtonWrapper /> {/* Ensure this is included */}
  </div>
);

const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '', element: <HomePage /> },
      { path: 'card-generator', element: <CardGenerator /> },
      { path: 'html-minifier', element: <HtmlMinifier /> },
      { path: 'css-minifier', element: <CssMinifier /> },
      { path: 'js-minifier', element: <JsMinifier /> },
      { path: 'card-generator-details', element: <CardGeneratorDetails /> },
      { path: 'grid-generator-details', element: <GridGeneratorDetails /> },
      {
        path: 'flexbox-generator-details',
        element: <FlexboxGeneratorDetails />,
      },
      {
        path: 'text-shadow-generator-details',
        element: <TextShadowGeneratorDetails />,
      },
      { path: 'grid-generator', element: <GridGenerator /> },
      { path: 'flexbox-generator', element: <FlexboxGenerator /> },
      { path: 'tooltip-generator', element: <TooltipGenerator /> },
      {
        path: 'tooltip-generator-details',
        element: <TooltipGeneratorDetails />,
      },
      { path: 'text-shadow-generator', element: <TextShadowGenerator /> },
      { path: 'blog', element: <BlogPage /> },
      { path: 'faq', element: <FaqPage /> },
      { path: 'about', element: <AboutPage /> },
      { path: 'contact', element: <ContactPage /> },
      { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
      { path: 'terms-of-service', element: <TermsOfServicePage /> },
      { path: 'sitemap', element: <SitemapPage /> },
      { path: 'box-shadow-generator', element: <BoxShadowGenerator /> },
      {
        path: 'box-shadow-generator-details',
        element: <BoxShadowGeneratorDetails />,
      },
      { path: '*', element: <NotFound /> },
    ],
  },
];

export const router = createBrowserRouter(routes);
