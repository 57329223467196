/* eslint-disable no-console */
// components/common/Footer.tsx
import React, { useEffect } from 'react';

import { api } from '../../services/api';
import './Footer.css';

export const Footer: React.FC = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/health-check');
        console.log('[API Output]', response.data);
      } catch (error) {
        console.error('[API Error]', error);
      }
    };

    fetchData();
  }, []);

  return (
    <footer className="footer shadow-top">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 RoundCodeBox. All rights reserved.</p>
      </div>
    </footer>
  );
};
